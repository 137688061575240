import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { createClient } from 'contentful';
// import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// Initialize Contentful client
const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

const NewsPage = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'news', // Adjust this to your Content Type ID in Contentful
          select: 'fields.title,fields.content,fields.media', // Adjust the fields to match your Contentful model
        });
        setNews(response.items);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <section className='my-20 md:my-40 px-6'>
      <h1 className='text-header font-bold md:text-3xl text-3xl capitalize'>latest news and events</h1>
      <div data-aos="zoom-in-up" className="py-10 container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {news.map((item) => {
          const { id, fields } = item;
          const { title, content, media } = fields;

          // Ensure media is defined and has a valid file property
          const imageUrl = media && media.length > 0 && media[0].fields && media[0].fields.file ? `https:${media[0].fields.file.url}` : null;

          // Limiting content preview to 100 characters
          const contentPreview = content ? content.content[0]?.content[0]?.value.substring(0, 150) : '';

          // Generate a URL-friendly title (replace spaces with hyphens and convert to lowercase)
          const newsTitleSlug = title.replace(/\s+/g, '-').toLowerCase();

          return (
            <div key={id} className="border rounded shadow-sm">
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt={media[0].fields.title || 'Image'}
                  className="w-full h-48 object-cover"
                />
              ) : (
                <div className="w-full h-48 bg-gray-200 flex items-center justify-center">No Image Available</div>
              )}
              <div className='p-4'>
                <h2 className="text-left text-header font-bold text-xl capitalize mb3">{title}</h2>
                <p className="mb-2">{contentPreview ? `${contentPreview}...` : 'No content available'}</p>
                <Link to={`/news/${newsTitleSlug}`} className="text-blue-500 hover:underline">
                  Read More
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default NewsPage;
