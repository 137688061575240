import React from 'react'
import "./loading.css"

function Loading() {
  return (
    <section className='h-screen'>
<div className="ring">CEPHER
  <span className='sp'></span>
</div>
</section>
  )
}

export default Loading