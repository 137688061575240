import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Aboutsection from '../components/home/aboutsection';
import Aboutcontent from '../components/about/aboutcontent';
import PageLayout from '../components/pagelayout';
import Ourcases from '../components/home/ourcases';
import LoadingAnimation from '../components/LoadingAnimation/Loading';

function About() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay before displaying the content

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <div className='overflow-hidden h-auto pt-16 z-1'>
      <Helmet>
        <title>About - Centre for Population and Health Research</title>
        <meta name="description" content="At Centre for Population and Health Research(CEPHER), we are dedicated to advancing research and training in the field of population and health." />
      </Helmet>
        <PageLayout title="About us" />
        <Aboutsection />
        <Aboutcontent />
        <Ourcases />
    </div>
  );
}

export default About;
