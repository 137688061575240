import React from 'react'
import id1 from '../../assets/ceo.jpeg'
import id2 from '../../assets/prof.jpeg'
import id3 from '../../assets/senior-research-fellow.jpeg'
import id4 from '../../assets/research-fellow.jpeg'
import id5 from '../../assets/barrister.jpeg'

const technicalStaff = [
  {
    id:1,
    image: id1,
    name: 'Professor Israel Olatunji Orubuloye',
    position: 'Chief Executive Officer and Director'
  },
  {
    id:2,
    image: id2,
    name: 'Professor Femi Tinuola',
    position: 'Director of Research and Training'
  },
  {
    id:3,
    image: id3,
    name: 'Dr. Foluke Adeosun',
    position: 'Senior Research Fellow in Population Health'
  },
  {
    id:4,
    image: id4,
    name: 'Mrs Morenikeji Adelekun',
    position: 'Research Fellow in Mental Health'
  },
  {
    id: 5,
    image: id5,
    name: 'Olatunji Tosin Orubuloye',
    position: 'Esq-Secratary'
  }
]

function Staffs() {

  return (
    <div data-aos="flip-right" className=' text-center '>
      <span className='text-blue-500 font-bold tracking-widest uppercase pb-3'>How we do it</span>
      <h1 className='text-header font-bold md:text-3xl text-2xl capitalize'>our staff</h1>
      <div className='flex flex-col md:flex-row w-screen ipad:grid ipad:grid-cols-2 ipad:place-items-center gap-5 mx-auto items-center justify-center mt-10'>
        {technicalStaff.map((staff) => (
          <div key={staff.id} className='w-[14rem] h-auto flex flex-col items-center'>
            <img src={staff.image} alt={`${staff.title} cover`} className='w-full h-56 rounded-full pb-2' />
            <div className='h-20 flex flex-col'>
              <h1 className='font-bold text-header capitalize text-sm'>{staff.name}</h1>
              <p className='text-paragraph text-sm'>{staff.position}</p>
            </div> 
          </div>
        ))}
      </div>
    </div>
  );
}

export default Staffs;

