import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaBook, FaFileAlt } from 'react-icons/fa';
import booksImage from '../../assets/pexels-olenkabohovyk-3646105.jpg';
import articlesImage from '../../assets/pexels-ekrulila-2333332.jpg';
import { Link } from 'react-router-dom';
import library1 from '../../assets/library1.jpeg'
import library2 from '../../assets/library2.jpeg'
import library3 from '../../assets/library3.jpeg'
import library4 from '../../assets/library4.jpeg'
import library5 from '../../assets/library5.jpeg'
import library6 from '../../assets/library6.jpeg'
import library7 from '../../assets/library7.jpeg'
import library8 from '../../assets/library8.jpeg'
import library9 from '../../assets/library9.jpeg'

const ResearchSection = () => {
  const images = [ 
    { src: library1, alt: 'test', caption: 'Law materials'},
    { src: library2, alt: 'test', caption: 'education materials'},
    { src: library3, alt: 'test', caption: 'management sciences'},
    { src: library4, alt: 'test', caption: 'political sciences'},
    { src: library5, alt: 'test', caption: 'engineering resources'},
    { src: library6, alt: 'test', caption: 'medicine collection'},
    { src: library7, alt: 'test', caption: 'nursing science'},
    { src: library8, alt: 'test', caption: 'study hall'},
    { src: library9, alt: 'test', caption: 'reading area'},
  ]
  const sections = [
    {
      title: 'Books',
      icon: <FaBook size={100} className="text-white" />,
      image: booksImage,
      link: '/research/books',
    },
    {
      title: 'Articles',
      icon: <FaFileAlt size={100} className="text-white" />,
      image: articlesImage,
      link: '/research/articles',
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className='px-6 my-20 md:my-40'>
      <h1  className='text-left text-header font-bold text-3xl capitalize'>publications</h1>
      <div className="flex flex-wrap justify-between gap-10 py-10">
        {sections.map((section, index) => (
          <Link to={section.link} key={index} className="w-full md:w-[45%]">
            <div
              data-aos="fade-right"
              className="relative h-80 rounded-lg cursor-pointer overflow-hidden shadow-lg transform transition-transform duration-300 hover:scale-105"
              style={{
                backgroundImage: `url(${section.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div className="absolute inset-0 bg-blue-900 bg-opacity-50 transition-opacity duration-300 hover:bg-opacity-70"></div>
              <div className="relative z-10 flex flex-col items-center justify-center h-full">
                {section.icon}
                <h2 className="text-4xl text-white mt-4 hover:text-blue-500">{section.title}</h2>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className='my-10'>
        <h1 className='text-left text-header font-bold text-3xl capitalize'>research activities</h1>
        <p className='text-sm text-paragraph mb-3'>Since inception in 1990, CEPHER has initiated and carried out the following research activities;</p>
        <ul className='list-decimal text-paragraph text-sm py-2'>
            <li>
                The Demographic and Social Consequences of COVID-19 Pandemic on Human Population in 
                Nigeria- sponsored by CEPHER (in progress).
            </li> 
            <li>
                Young people&apos;s Attitude towards Alcohol and Binge Drinking- sponsored by CEPHER 2004-2021.
            </li>
            <li>
                Community based Distribution and Social Marketing of Contraceptives in Ekiti District- sponsored 
                by the David and Lucile Packard Foundation, USA 1999-2000
            </li>
            <li>
                Health Treatment in Nigeria: The Cultural, Social and Behavioural Determinants- sponsored by 
                the Rockefeller, Foundation New York 1992-2010
            </li>
            <li>
                Sexual networking in the Ekiti District of South-West Nigeria- sponsored by the National Centre 
                for Epidemiology and Population Health, Health Transition Centre, Australia National University, 
                Canberra Australia 1990/1991.
            </li>
            <li>
                Sexual Networking, STDS and HIV/AIDs in Nigeria sponsored by the Swedish International
                Research Agency, Sweden, 1991-1995
            </li>
            <li>
                Women&apos;s Role in Decision-Making at the Household Level in Ondo State- sponsored by 
                International Centre for Research on Women, Washington DC. 1996-1997.
            </li>
        </ul>
      </div>
      <div>
      <h1 className="text-left text-header font-bold text-3xl capitalize">Our Research Library</h1>
      <p className="text-sm text-paragraph mb-3">
        Our research library is the cornerstone of our academic work, providing researchers, scholars, and students with
        access to a comprehensive collection of books, journals, and digital resources. Our extensive library is designed to
        support and enhance the research activities of our organization, offering a quiet and well-equipped environment for deep study and exploration.
      </p>

      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="p-2">
            <div className="relative">
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-80 md:h-[22rem] object-cover rounded-lg shadow-lg"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center text-white text-lg font-medium capitalize">
                {image.caption}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>

    </section>
  );
};

export default ResearchSection;
