import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import MapComponent from '../components/contact/mapcomponent'
import PageLayout from '../components/pagelayout'
import ContactForm from '../components/contact/formcomponent'
import LoadingAnimation from '../components/LoadingAnimation/Loading'

function Contact() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay before displaying the content

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }
  return (

    <div className='pt-16'>
      <Helmet>
        <title>Contact Us - Centre for Population and Health Research</title>
        <meta name="description" content="Get in touch with the Centre for Population and Health Research (CEPHER).
         Reach out to us for inquiries, collaboration opportunities, or more information about our research and 
         training programs." />
      </Helmet>
      <PageLayout title={'contact us'} />
        <MapComponent />
        <ContactForm />
    </div>
  )
}

export default Contact