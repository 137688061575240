import React from 'react';
// import backgroundImage from '../../assets/scott-graham-5fNmWej4tAA-unsplash.jpg'
import backgroundImage from '../../assets/pexels-shkrabaanthony-5816298.jpg'


const HeroSection = () => {
  return (
    <div className="bg-center w-full md:h-screen bg-white ipad:h-[60vh] flex flex-col-reverse md:flex-row gap-10 md:gap-0 items-center justify-center mt-16 md:mt-10">
      <div className="text-center  flex flex-col items-center px-6 w-full md:w-3/5 h-[35vh] md:h-screen md:justify-center">
        <h1 className="text-3xl md:text-4xl font-bold text-header">Advancing Research on the socio-cultural and economic determinants of population health</h1>
        <div className="mt-12 md:mt-8 flex flex-col md:flex-row items-center">
        <a href="/about">
          <button className="bg-blue-500 text-white px-4 py-3 md:py-4 rounded w-full md:w-auto mb-4 md:mb-0 hover:bg-blue-300">
            Learn More About Our Work
          </button>
        </a>
        </div>
      </div>
      <div className='w-full md:w-2/5 h-[50vh] md:h-full'>
        <img className='h-full w-full md:rounded-l-[70%] md:rounded-r-none' src={backgroundImage} alt="background" />
      </div>
    </div>
  );
};

export default HeroSection;


