import React from 'react'
import researchImage from '../../assets/researchteam.jpeg'

function Researchteam() {
  return (
    <section data-aos="zoom-in-up" className="flex flex-col-reverse md:flex-row items-center gap-5 px-6 my-20 md:my-40 ">
  <div className="w-full md:w-1/2">
  <span className='text-blue-500 font-bold tracking-widest uppercase pb-3'>our cases you can see</span>
    <h2 className="md:text-3xl text-2xl font-bold text-header mb-4">Explore Our Research</h2>
    <p className="text-paragraph text-sm mb-6">
      At Centre for Population and Health Research, we are dedicated to advancing knowledge in population and health. Dive into our latest studies, publications, and findings. Discover how our research is shaping the future of health and well-being.
    </p>
    <a 
      href="/research" 
      className="inline-block bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-300 transition-colors duration-300"
    >
      Learn More
    </a>
  </div>
  <div className="w-full md:w-1/2">
    <img 
      src={researchImage} 
      alt="Research at CEPHER" 
      className="rounded-lg shadow-lg w-full h-48 md:h-96" 
    />
  </div>
</section>
  )
}

export default Researchteam