import React from 'react';
import { FaGavel, FaChartLine, FaRegCalendarAlt } from 'react-icons/fa';

const aboutContentData = [
  {
    icon: FaGavel,
    title: 'Governance',
    description: `The chief executive officer and director directs the activities of Centre for Population and
     Health Research as laid down by the board of trustees as well as enforces the rules guiding the policies, 
     processes, rules, and regulations.`
  },
  {
    icon: FaChartLine,
    title: 'Finance',
    description: `The research programmes of the organization are financed with grants and donations from both 
    international and local organizations.`
  },
  {
    icon: FaRegCalendarAlt,
    title: 'Institutional Planning',
    description: `The organization operates a two-tier planning strategy- short and long term planning systems. 
    The short term planning system focuses on day-to-day issues, while the long term strategy deals with growth or
     response to change that may have long-term effects.`
  }
];

function AboutContent() {
  return (
    <section className='mt-10'>
      <div className='mb-20 px-6'>
        <h1 className='text-header text-3xl font-bold  capitalize'>Who we are</h1>
        <p className='text-paragraph text-sm mb-3'>
          The Centre for Population and Health Research (CEPHER) was founded in 1990 and registered on the 10th
          May, 2000 with Corporate Affairs Commission (CAC) of the Federal Republic of Nigeria with Registration 
          No. 12,873, as a Non for Profit Organization.
        </p>
        <p className='text-paragraph text-sm'>
          At inception, Center for population and health took off at the temporary office located at the Ile-Abiye Hospital, Ado-Ekiti 
          Ekiti State, before relocating to a permanent office accommodation christened Modupe Olufunke 
          House along Polytechnic Road, Ado-Ekiti. The Lagos office is located at No.8 Lapai close, Thomas 
          Estate, Ajah Lagos. In 2017 another office, consisting of a physical library and E-library was located 
          at No.12 Ilawemo Quarters, Are-Ekiti, Ekiti State. The physical library holds well over 5,000 books, 
          journals and research materials mainly in social sciences.
        </p>
      </div>
      <div className='mb-20 px-6'>
        <h1 className='text-header text-3xl font-bold'>Our Objectives</h1>
        <p className='text-paragraph text-sm pb-3'>The objectives of the Research outfit include:</p>
        <ul className='list-disc text-paragraph text-sm'>
          <li>
            Investigation of the relationship between health behaviour and socio-economic and cultural 
            conditions as mediated by education of parents
          </li>
          <li>
            Investigation of the cultural, social and behavioural determinants of health treatment.
          </li>
          <li>
            Investigation of sexual networking, sexually transmission infections, HIV/ AIDS and the 
            effects on health.
          </li>
          <li>
            Development of a platform for the training and monitoring of young researchers in the field of 
            population and Health related studies
          </li>
        </ul>
      </div>
      <div className='px-6'>
        <h1 className='text-header text-3xl font-bold mb-5'>Governance and Structure</h1>
      <div className='flex flex-col ipad:grid ipad:grid-cols-2 ipad:gap-10 md:flex-row justify-around gap-10 md:gap-0 items-center md:items-stretch'>
      {aboutContentData.map((item, index) => (
        <div key={index} className='bg-[#e5e5e5] shadow-xl flex flex-col items-center gap-2 justify-center w-full ipad:w-[45vw] md:w-[30vw] rounded-md px-4 py-10 h-auto'>
          <item.icon className='text-blue-500' style={{ fontSize: '50px' }} />
          <h1 className='text-header text-2xl'>{item.title}</h1>
          <p className='text-paragraph text-sm'>
            {item.description}
          </p>
        </div>
      ))}
      </div>
      <p className='text-paragraph font-bold my-10'>
          Centre for Population and Health Research(CEPHER) operates a decentralized management system. The director of research supervises the research and training activities, 
          while the research fellows execute the research programs with the assistance of ad-hoc research personnel who are engaged from time to time. 
          The secretary/ICT manager manages the day-to-day running of the office, implements procedures, and carries out administrative duties as well as 
          implementing ICT strategies.
        </p>
      </div>
    </section>
  );
}

export default AboutContent;
