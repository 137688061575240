import React, { useEffect, useState } from 'react'
import HeroSection from '../components/home/herosection'
import Aboutsection from '../components/home/aboutsection'
import Ourcases from '../components/home/ourcases'
import LogoSlider from '../components/home/logoslider'
import Researchteam from '../components/home/researchteam'
import Staffs from '../components/home/staffs'
import LoadingAnimation from '../components/LoadingAnimation/Loading'
import NewsPage from '../components/news/newsComponent'
import JobsOpportunity from '../components/opportunities/jobsOpportunity'

function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1 second delay before displaying the content

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }
  return (
    <div className='overflow-hidden'>
        <HeroSection />
        <Aboutsection />
        <Staffs />    
        <Researchteam />
        <JobsOpportunity />
        <NewsPage />
        <LogoSlider />
        <Ourcases />
  </div>
  )
}

export default Home