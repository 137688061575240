import React from 'react';
import articleImage1 from '../../assets/article1.webp';
import articleImage2 from '../../assets/article2.webp';

const articlesData = [
  {
    id: 1,
    image: articleImage1,
    title: "Africa's Transformation: Diversification and Innovation Spur Growth and Global Influence",
    excerpt: "Africa's population is growing due to improved health services, declining mortality rates, and high fertility rates. Africa currently accounts for 19% of the world's population and is expected to account for 26% by 2050. Low contraceptive use, improved health services, and increased female education also drive this growth.",
    link: 'https://en.sputniknews.africa/20240730/africas-transformation-diversification-and-innovation-spur-growth-and-global-influence-1067672768.html',
  },
  {
    id: 2,
    image: articleImage2,
    title: "Future Generations: Africa's Population Growth & Its Impact",
    excerpt: "Africa's population is projected to grow more than twice by 2100, reaching 3.8 billion, according to World Population Prospect 2024 report. This significant growth presents both opportunities and challenges for the continent's future. Global South Pole's host joins an expert to discuss the implications and strategies for sustainable development.",
    link: 'https://en.sputniknews.africa/20240730/future-generations-africas-population-growth--its-impact-1067675714.html',
  },
];

const ArticlesComponent = () => {
  return (
    <section className="px-6 py-10">
      <div className="grid gap-8 md:grid-cols-2">
        {articlesData.map((article) => (
          <div key={article.id} className="bg-white rounded-lg shadow-md overflow-hidden">
            <img src={article.image} alt={article.title} className="w-full h-60 object-cover" />
            <div className="p-6">
              <h2 className="text-xl md:text-2xl font-semibold mb-4 text-header">{article.title}</h2>
              <p className="mb-6 text-paragraph text-sm">{article.excerpt}</p>
              <a
                href={article.link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-300"
              >
                Read More
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ArticlesComponent;
