import React, { useState, useEffect } from 'react'
import ArticlesComponent from '../components/research/articlescomponent'
import PageLayout from '../components/pagelayout'
import LoadingAnimation from '../components/LoadingAnimation/Loading'

function Articlespage() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay before displaying the content

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }
  return (
    <div className='overflow-hidden h-auto pt-16 z-1'>
        <PageLayout title={'articles'} />
        <ArticlesComponent />
    </div>
  )
}

export default Articlespage