import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IoMdMenu, IoMdClose } from "react-icons/io";
import logo from '../assets/cepher-logo.jpeg'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
    <header className="bg-white flex justify-between items-center md:block px-4 py-2 shadow-2xl fixed top-0 right-0 w-screen z-30">
      <div className="flex justify-between items-center px-0 md:px-6">
        <NavLink to="/" className="flex-shrink-0 flex-row flex items-center">
          <img src={logo} className='h-[4rem] w-[12rem]' alt="cepher logo" />
        </NavLink>
        <div className="hidden md:flex space-x-10">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? 'text-darkBlue border-blue-500 border-b-2' : 'text-grayishBlue border-transparent hover:text-blue-500 transition-colors duration-300'
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? 'text-darkBlue border-blue-500 border-b-2' : 'text-grayishBlue border-transparent hover:text-blue-500 transition-colors duration-300'
            }
          >
            About
          </NavLink>
          <NavLink
            to="/research"
            className={({ isActive }) =>
              isActive ? 'text-darkBlue border-blue-500 border-b-2' : 'text-grayishBlue border-transparent hover:text-blue-500 transition-colors duration-300'
            }
          >
            Research
          </NavLink>
          <NavLink
            to="/news-and-media"
            className={({ isActive }) =>
              isActive ? 'text-darkBlue border-blue-500 border-b-2' : 'text-grayishBlue border-transparent hover:text-blue-500 transition-colors duration-300'
            }
          >
            News
          </NavLink>         
        </div>
        <a href="/contact">
        <button className="capitalize hidden md:block bg-blue-500 hover:bg-blue-300 text-white text-sm px-6 w-40 py-4 rounded">
          get involved
        </button>
        </a>
      </div>
      <div className="md:hidden">
        <button onClick={toggleMenu}>
          {isOpen ? <IoMdClose /> : <IoMdMenu />} 
        </button>
      </div>
    </header>
  {isOpen && (
    <div className="md:hidden fixed top-20 left-0 w-full z-10">
      <div data-aos="flip-down" className="bg-white py-6 flex flex-col items-center space-y-2 shadow-lg">  
      <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? 'text-darkBlue border-blue-500 border-b-2' : 'text-grayishBlue border-transparent hover:border-limeGreen transition-colors duration-300'
            }
            onClick={toggleMenu}
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? 'text-darkBlue border-blue-500 border-b-2' : 'text-grayishBlue border-transparent hover:border-limeGreen transition-colors duration-300'
            }
            onClick={toggleMenu}
          >
            About
          </NavLink>
          <NavLink
            to="/research"
            className={({ isActive }) =>
              isActive ? 'text-darkBlue border-blue-500 border-b-2' : 'text-grayishBlue border-transparent hover:border-limeGreen transition-colors duration-300'
            }
            onClick={toggleMenu}
          >
            Research
          </NavLink>
          <NavLink
            to="/news-and-media"
            className={({ isActive }) =>
              isActive ? 'text-darkBlue border-blue-500 border-b-2' : 'text-grayishBlue border-transparent hover:border-limeGreen transition-colors duration-300'
            }
            onClick={toggleMenu}
          >
            News
          </NavLink> 
          <a href="/contact">
        <button className="capitalize md:block bg-blue-500 hover:bg-blue-300 text-white text-sm px-6 w-40 py-4 rounded">
          get involved
        </button>
        </a>           
      </div>
    </div>
  )}
</div>
  );
};

export default Navbar;
