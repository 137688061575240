import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { IoHomeOutline } from "react-icons/io5";
import { MdOutlineLocalPhone } from "react-icons/md";
import { CiMail } from "react-icons/ci";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_wu0nzkr', 'template_hu9mc3c', e.target, 'rU6rUZuu29LVvSFJx')
      .then((result) => {
          alert('Message sent successfully!');
          setFormData({ name: '', email: '', message: '' }); // Clear form
      }, (error) => {
          alert('Message sending failed. Please try again.');
      });
  };

  return (
    <section className="px-6 py-10">
      <h1 className="text-3xl mb-4 text-header capitalize">get in touch</h1>
      <main className='flex flex-col md:flex-row gap-10'>
      <form onSubmit={handleSubmit} className='md:w-2/3 w-full' >
        <div className="mb-4 flex flex-col md:flex-row items-center gap-5">
            <div className='w-full md:w-1/2'>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                       value={formData.name}
                       onChange={handleChange}
                       className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                       required
                    />
            </div>
            <div className='w-full md:w-1/2'>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                   <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      required
                />
            </div>
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            rows="5"
            required
          />
        </div>
        <button
          type="submit"
          className="inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-300"
        >
          Send Message
        </button>
      </form>
      <aside className='w-full md:w-1/3 h-auto'>
        <div className='flex gap-3 items-start'>
            <IoHomeOutline style={{ fontSize: '50px' }}  />
            <span className='text-paragraph font-bold'>Modupe Olufunke House, Opposite Nigeria Police Station, Federal Polytechnic Road, Ado-Ekiti</span>
        </div>
        <div className='flex gap-3 items-start my-5'>
        <MdOutlineLocalPhone style={{ fontSize: '30px' }} />
        <span className='text-paragraph font-bold'>+234-8035060522</span>
        </div>
        <div className='flex gap-3 items-start'>
            <CiMail style={{ fontSize: '30px' }} />
            <span className='text-paragraph font-bold'> cepher@gmail.com</span>
        </div>
      </aside>
      </main>
    </section>
  );
};

export default ContactForm;
