import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './pages/home';
import About from './pages/about';
import Navbar from './components/navbar';
import Research from './pages/research';
import Articlespage from './pages/articles';
import Contact from './pages/contact';
import Books from './pages/books';
import Footer from './components/footer';
import ScrollToTop from './components/ScrollToTop';
import News from './pages/news';
import NewsDetail from './components/news/newsDetail';
import JobsDetail from './pages/jobs';
import './App.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animation in milliseconds
    });
  }, []);


  return (
    <Router> 
      <ScrollToTop />
      <Navbar />
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/research" element={<Research />} />
      <Route path="/research/articles" element={<Articlespage />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/research/books" element={<Books />} />
      <Route path="/news-and-media" element={<News />} />
      <Route path="/news/:titleSlug" element={<NewsDetail />} />
      <Route path="/jobs/:titleSlug" element={<JobsDetail />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;


