import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import ResearchSection from '../components/research/researchcomponents'
import PageLayout from '../components/pagelayout'
import LoadingAnimation from '../components/LoadingAnimation/Loading'

function Research() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay before displaying the content

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }
  return (
    <div className='overflow-hidden h-auto pt-16 z-1'> 
      <Helmet>
        <title>Research - Centre for Population and Health Research</title>
        <meta name="description" content="Dive into our latest studies, publications, and findings. Discover how our research is shaping the future of health and well-being." />
      </Helmet>
      <PageLayout title={"Research"} />
      <ResearchSection />
    </div>
  )
}

export default Research