import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { createClient } from 'contentful';
import bgimage from '../../assets/claudio-schwarz-_wDZkpybAfY-unsplash.jpg';

// Initialize Contentful client
const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

// Helper function to extract plain text from rich text content and ignore h6 tags
const extractPlainText = (richTextDocument) => {
  if (!richTextDocument) return '';
  return richTextDocument.content
    .filter((node) => node.nodeType !== 'heading-6') // Filter out 'h6' tags
    .map((node) => node.content.map((child) => child.value || '').join(''))
    .join('');
};

function JobsOpportunity() {
  const [jobs, setJobs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current job index

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'opportunities',
          select: 'fields.title,fields.content',
        });
        setJobs(response.items);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Handle sliding logic
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        (prevIndex + 1) % jobs.length // Loop back to the first item when reaching the end
      );
    }, 4000); // Adjust timing to change slide every 3 seconds

    return () => clearInterval(slideInterval); // Clear interval on unmount
  }, [jobs.length]);

  if (jobs.length === 0) return null; // If no jobs are fetched, return nothing

  const { title, content } = jobs[currentIndex]?.fields || {};

  const plainTextContent = extractPlainText(content);
  const contentPreview = plainTextContent.length > 500 
    ? `${plainTextContent.substring(0, 500)}...`
    : plainTextContent;

  const opportunitySlug = title?.replace(/\s+/g, '-').toLowerCase();

  return (
    <section className='py-10'>
      <h1 className='text-header font-bold md:text-3xl text-2xl capitalize mb-10 px-6 text-center'>Conference opportunities</h1>
    <main className="py-20 relative">
      
      <div className="absolute inset-0 bg-gradient-to-r from-blue-600 via-blue-800 to-blue-900 opacity-80 z-10"></div>
      <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: `url(${bgimage})` }}></div>

      {/* Job Display */}
      <div className="relative z-20 flex items-center justify-center text-white text-center px-6 md:px-12">
        <div>
          <h2 className="text-2xl md:text-4xl font-bold mb-4">{title}</h2>
          <p className="text-sm md:text-lg mb-6">{contentPreview}</p>
          <Link to={`/jobs/${opportunitySlug}`} className="px-4 py-2 bg-blue-500 rounded-lg hover:bg-blue-600 transition">
            Learn More
          </Link>
        </div>
      </div>
    </main>
    </section>
  );
}

export default JobsOpportunity;
