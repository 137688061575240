import React from 'react'
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn, FaSquareInstagram } from "react-icons/fa6";

function footer() {
  return (
    <section className='bg-[#1c1c1c] text-white'>
        <main className='flex justify-between border-b-2 px-10 py-20 border-[#2d2d2d] flex-col md:flex-row'>
        <div className='md:w-1/3 w-full flex flex-col md:items-start items-center'>
            <h1 className='md:text-3xl text-xl font-bold mb-3 text-center md:text-start'>Stay connected with us on social media</h1>
        <div className='flex space-x-3'>
        <div className='bg-gray-800 p-3 rounded-full hover:bg-white hover:text-gray-800 cursor-pointer'>
            <FaTwitter className="" />
        </div>
        <div className='bg-gray-800 p-3 rounded-full hover:bg-white hover:text-gray-800 cursor-pointer'>
            <FaLinkedinIn />
        </div>
        <div className='bg-gray-800 p-3 rounded-full hover:bg-white hover:text-gray-800 cursor-pointer'>
            <FaSquareInstagram />
        </div>
        </div>
        </div>
        <div className='w-full md:w-auto text-center md:text-start my-5 md:my-0'>
            <h3 className='text-2xl font-semibold capitalize mb-3'>company</h3>
            <ul className='space-y-4'>
                <li>
                    <a href="/about">About</a>
                </li>
                <li>
                    <a href="/news-and-events">News & Events</a>
                </li>
                <li>
                    <a href="/research">Research</a>
                </li>
            </ul>
        </div>
        <div className='w-full text-center md:w-auto md:text-start'>
            <h3 className='text-2xl font-semibold capitalize mb-3'>contact us</h3>
            <ul className='space-y-4'>
                <li>+234-8035060522</li>
                <li>
                    <a href="mailto:cepher@gmail.com" className="text-white">cepher2008@gmail.com</a>
                </li>

            </ul>
        </div>
        </main>
        <aside className='pl-0 md:pl-10 py-4 text-center md:text-start'>
        <p class="text-sm">&copy; <span id="year"></span>All rights reserved - Centre for Population and Health Research</p>
        </aside>
    </section>
  )
}

export default footer