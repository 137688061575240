import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; 
import { createClient } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Loading from '../LoadingAnimation/Loading';

// Initialize Contentful client
const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

const NewsDetail = () => {
  const { titleSlug } = useParams(); // Extract the dynamic part of the URL
  const [newsItem, setNewsItem] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNewsItem = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'news', // Adjust this to your Content Type ID in Contentful
          'fields.title[match]': titleSlug.replace(/-/g, ' '), // Match the title slug to the Contentful title
          select: 'fields.title,fields.content,fields.date,fields.media', // Adjust fields as needed
        });

        // Assuming only one item is returned
        const matchedNews = response.items[0];
        setNewsItem(matchedNews);
      } catch (error) {
        console.error('Error fetching news item:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching is complete
      }
    };

    fetchNewsItem();
  }, [titleSlug]);

  if (loading) return <Loading />;

  if (!newsItem) return <div>News item not found.</div>;

  const { fields } = newsItem;
  const { title, content, date, media } = fields;
  const imageUrl = media && media.length > 0 && media[0].fields.file ? `https:${media[0].fields.file.url}` : null;
  const formattedDate = new Date(date).toLocaleDateString();

  return (
    <div className="container mx-auto overflow-hidden py-12 mt-16 z-1 px-6 md:px-24">
      <h1 className="text-center text-header font-bold text-3xl capitalize mb-8">{title}</h1>
      {imageUrl && (
        <img
          src={imageUrl}
          alt={media[0].fields.title || 'Image'}
          className="w-full h-64 md:h-96 object-cover rounded-lg"
        />
      )}
      <p className="text-paragraph text-sm font-bold mb-4">{formattedDate}</p>
      <div className='text-sm text-paragraph font-semibold'>
        {documentToReactComponents(content)}
      </div>
    </div>
  );
};

export default NewsDetail;
