import React, { useState, useEffect } from 'react'
import Bookscomponent from '../components/research/bookscomponent'
import PageLayout from '../components/pagelayout'
import LoadingAnimation from '../components/LoadingAnimation/Loading'

function Books() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay before displaying the content

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }
  return (

    <div className='overflow-hidden h-auto pt-16 z-1'>
        <PageLayout title={'books'} />
        <Bookscomponent />
    </div>
  )
}

export default Books