import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { createClient } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PageLayout from '../components/pagelayout';
import LoadingAnimation from '../components/LoadingAnimation/Loading';
import { useParams } from 'react-router-dom';


// Initialize Contentful client
const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

// Custom options for rich text rendering
const options = {
  renderNode: {
    'paragraph': (node, children) => (
      <p style={{ marginBottom: '1em', color: '#464d65' }}>{children}</p>
    ),
    'heading-3': (node, children) => (
      <h3 style={{fontSize: '1.5em', fontWeight: '600', marginBottom: '10px', color: '#072366'}}>{children}</h3>
    ),
    'heading-4': (node, children) => (
      <h4 style={{ fontSize: '1.5em', color: '#3b82f6', margin: '20px' }}>{children}</h4>
    ),
    'heading-5': (node, children) => (
      <h5 style={{color: '#072366'}}>{children}</h5>
    ),
    'heading-6': (node, children) => (
      <h6 style={{textAlign: 'center', fontSize: '1em', marginBottom: '3rem'}}>{children}</h6>
    )
    // Add more renderers for other node types as needed
  },
};

function JobsDetail() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { titleSlug } = useParams(); // Get the slug from the URL

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'opportunities',
          select: 'fields.title,fields.content,fields.websiteLink', 
        });
        setJobs(response.items);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }

  // Find the job with the matching slug
  const job = jobs.find((item) => {
    const { title } = item.fields;
    const opportunitySlug = title.replace(/\s+/g, '-').toLowerCase();
    return opportunitySlug === titleSlug;
  });

  if (!job) {
    return <p>Job not found</p>;
  }

  const { fields } = job;
  const { title, content, websiteLink } = fields;

  return (
    <div className='overflow-hidden h-auto pt-16'>
      <Helmet>
        <title>{title} - Centre for Population and Health Research</title>
        <meta name="description" content={`Explore ${title} and apply today at the Centre for Population and Health Research.`} />
      </Helmet>
      <PageLayout title={'Conference Opportunities'}  />
      <div className="border rounded shadow-sm mb-6 p-6">
        <h2 className="text-center text-header font-bold md:text-3xl text-2xl capitalize">
          {title}
        </h2>
        <div className="mb-2">
          {documentToReactComponents(content, options)}
        </div>
        <a href={websiteLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
          <button className='bg-blue-500 text-white px-5 py-2 md:py-3 rounded w-full md:w-auto mb-4 md:mb-0 hover:bg-blue-300'>
            Apply Here
          </button>
        </a>
      </div>
    </div>
  );
}

export default JobsDetail;
