import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import PageLayout from '../components/pagelayout'
import LoadingAnimation from '../components/LoadingAnimation/Loading'
import NewsPage from '../components/news/newsComponent';

function News() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay before displaying the content

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }
  return (

    <div className='overflow-hidden h-auto pt-16 z-1'>
      <Helmet>
        <title>Latest News and Updates - Centre for Population and Health Research</title>
        <meta name="description" content="Stay informed with the latest news, research findings, and updates from 
        the Centre for Population and Health Research (CEPHER). Discover insights into the
         socio-cultural and economic determinants of population health." />
      </Helmet>
      <PageLayout title={'news and media'} />
      <NewsPage />
    </div>
  )
}

export default News