import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';  // Import Leaflet CSS
import L from 'leaflet';

// Ensure Leaflet's default marker icon path is set correctly
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const MapComponent = () => {
  const position = [7.605542988250567, 5.288630707141039]; // Coordinates for your organization
  const mapboxToken = 'pk.eyJ1IjoibGFtYmEwMDAyIiwiYSI6ImNtMDJzMW45ZjA0OXEydnIxNTFmMmN3eTYifQ.AY4Uh1cjmtHB5Mlu38u8iw'; // Replace with your Mapbox token

  return (
    <MapContainer center={position} zoom={15} style={{ height: '70vh', width: '95%', margin: '10px auto', zIndex: '1' }}>
      <TileLayer
        url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapboxToken}`}
        attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
      />
      <Marker position={position}>
        <Popup>
          CEPHER office
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapComponent;
