import React from 'react';
import book1 from '../../assets/book1.jpg';
import book2 from '../../assets/book2.jpg'
import book3 from '../../assets/book3.jpg'
import book4 from '../../assets/book4.jpg'
import book5 from '../../assets/book5.jpeg'

const booksData = [
  {
    id: 1,
    image: book1,
    title: 'Health Treatment in Nigeria',
    description:
      `The cultural and social and behavioral determinants of health is a topic which has received little attention: 
       most of the earlier social science health research has concentrated on health services utilization. 
       Events in recent years have shown that a wide range of cultural, social and behavioural factors significantly influence
        health treatment. This study demonstrates a clear association between making treatment decisions and paying for treatment.`
  },
  {
    id: 2,
    image: book2,
    title: 'essays in media, culture and society',
    description: `This book is a conpendium of Essays in media, culture and society. It is a product of 
    literature review and research on contemporary issues in media and commmunication studies and sociology
    of culture and the media. Culture is generally dominated and shaped inmany parts of the world by the 
    mass media-the print, film, radio, television and now the internet.`
  },
  {
    id: 3,
    image: book3,
    title: 'the study of human populations',
    description: `The publication of introduction to population analysis in 1986 was a response to the dearth of 
    basic textbooks on population studies. The book was addressed to scholars in social atudies and related 
    disciplines, as well as to non-specialists in demography or population studies and people who share a common interest
    in population issues that affect all members of the society one way or another.`
  },
  {
    id: 4,
    image: book4,
    title: 'disease, illness and society',
    description: `This book is a product of many years of teaching and research in sociology of illness 
    and health seeking behaviour. This book is addressed to students and scholars in the field of sociology of Health at both the 
    undergraduate and post-graduate levels in the faculties of social and health sciences. It is also useful to people who share a 
    common need in the understanding of how and why people tend to view the event of disease from the perspective of their culture 
    and react to the event of illness in different ways.`
  },
  {
    id: 5,
    image: book5,
    title: 'binge drinking: a 21st century epidemic among the youths',
    description: `Chris Olumide Orubuloye, the senior author of this publication had his primary and secondary school education
    in Nigeria before he left for Australia in 1997. He obtained a diploma certificate in Marketing at the University of Canberra,
    Australia and later a Bachelor of Arts degree with second class upper division in media and communication studies at the university 
    of Glamorgan, now University of South Wales, United Kingdom`
  }
];

function BooksComponent() {
  return (
    <section className='my-20 flex flex-col w-screen items-center justify-center px-6 gap-10'>
      {booksData.map((book) => (
        <div key={book.id} className='flex flex-col md:flex-row w-full md:w-[85vw] h-auto md:h-52 items-center border border-gray-300 rounded-lg p-4'>
          <img src={book.image} alt={`${book.title} cover`} className='w-[75%] h-72 md:w-2/12 md:h-full rounded-ss-md mb-2 md:mb-0' />
          <div className='ml-0 md:ml-4 w-full md:w-9/12'>
            <h1 className='text-xl md:text-2xl font-bold text-header capitalize'>{book.title}</h1>
            <p className='text-paragraph text-sm'>{book.description}</p>
          </div>
        </div>
      ))}
    </section>
  );
}

export default BooksComponent;


