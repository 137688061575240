import React from 'react';
import Bg from '../assets/page-gradient.jpg';

const PageLayout = ({ title }) => {
  return (
    <div 
      className="h-[20vh] md:h-[40vh] bg-cover bg-center flex items-center justify-center"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <h1 className="text-white text-2xl md:text-4xl font-bold capitalize">{title}</h1>
    </div>
  );
};

export default PageLayout;
