import { useEffect, useState } from 'react';
import Bg from '../../assets/page-gradient.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Ourcases = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Initialize AOS
    AOS.init({ duration: 1000 });

    // Check if the screen width is less than 768px (mobile)
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Run once on mount
    handleResize();

    // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section>
      <div className='flex flex-col md:flex-row gap-10 md:gap-5 justify-around w-screen text-white py-10' style={{ backgroundImage: `url(${Bg})` }}>
        <div data-aos={isMobile ? 'fade-right' : ''} className='text-center'>
          <span className='text-4xl font-bold'>40+</span>
          <p className='capitalize font-semibold text-sm'>international conferences</p>
        </div>
        <div data-aos={isMobile ? 'fade-left' : ''} className='text-center'>
          <span className='text-3xl font-bold'>34+</span>
          <p className='capitalize font-semibold text-sm'>years of research</p>
        </div>
        <div data-aos={isMobile ? 'fade-right' : ''} className='text-center'>
          <span className='text-3xl font-bold'>100+</span>
          <p className='capitalize font-semibold text-sm'>research output</p>
        </div>
      </div>
    </section>
  );
};

export default Ourcases;
